import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
// CUSTOM COMPONENTS
import { Paragraph , H6 } from 'components/typography';
import AvatarLoading from 'components/avatar-loading';
import FlexRowAlign from 'components/flexbox/FlexRowAlign';
import { getData } from 'api-handlers/apiFunctions';
import { useEffect, useState, useContext  } from 'react';
import { UserContext } from 'contexts/userContext';


export default function UserAccount() {
  const [loading, setLoading] = useState(true); 
  const [userInfo, setUserInfo] = useState({});
  const { userProfilePic, updateUserProfilePic } = useContext(UserContext);

  const fetchUserInfo = async () => {
    try {
      const result = await getData( process.env.REACT_APP_USER_INFO_ENDPOINT )
      if (result.success){ 
        console.log(result.data)
        setUserInfo(result.data.user);
        localStorage.setItem("userInfo" , JSON.stringify(result.data.user))
      }
    } 
    catch (error) { console.log({error}) }
    finally{
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchUserInfo()
    console.log("<<<<< User Info Fetched >>>>>")
  } , [])



  return (
    <FlexRowAlign flexDirection="column" py={2}>
      <Badge color="primary">
        <AvatarLoading
          alt="user"
          percentage={60}
          src={userProfilePic}
          sx={{
            width: 50,
            height: 50
          }}
        />
      </Badge>

      <Box textAlign="center" pt={1} pb={2}>
        <H6 variant="outlined" label="Welcome Back" size="small" />

        <Paragraph fontSize={16} fontWeight={600} mt={1}>
          {userInfo?.first_name}
        </Paragraph>

        <Paragraph fontSize={13} fontWeight={500} color="text.secondary" mt={0.25}>
          {userInfo?.role}
        </Paragraph>
      </Box>
    </FlexRowAlign>
  );


}