import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userProfilePic, setUserProfilePic] = useState(() => {
    return localStorage.getItem('userProfilePic') || '/static/user/user_logo_avatar.png';
  });

  const updateUserProfilePic = (newProfilePic) => {
    setUserProfilePic(newProfilePic);
    localStorage.setItem('userProfilePic', newProfilePic);
  };

  return (
    <UserContext.Provider value={{ userProfilePic, updateUserProfilePic }}>
      {children}
    </UserContext.Provider>
  );
};
